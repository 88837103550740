
import Vue from "vue"
import { cleControle, dbSAB, sqlDatetime, variables } from "../plugins/variables"
import axios from "axios"

export default Vue.extend({
  name: "login",
  data() {
    return {
      ut_id: -1,
      motdepasse: "",
      utilisateurs: this.$store.state.utilisateurs,
      utilisateurs_options: [] as any[],
      poste: "m",
      postes: [] as any[],
      dictionnaire: [] as string[],
      unsubscribe: null as any
    }
  },
  methods: {
    connexion() {
      const url = this.$store.state.ajaxurl + "login.php"
      axios
        .post(url, {
          action: "CONNEXION",
          ut_id: this.ut_id,
          motdepasse: this.motdepasse,
          poste: this.poste
        })
        .then(async response => {
          if (response.data.authentification == "oui") {
            const db = await dbSAB()
            const store = db.transaction("sab_co", "readwrite").store
            const dateCo = sqlDatetime(new Date())
            store.put({
              ut_id: this.ut_id,
              nom: this.trouveUtilisateurNomPrenom(this.ut_id),
              authentifie: true,
              langue: response.data.langue,
              sp_id: response.data.sp_id,
              date: dateCo
            })
            variables.authentifie = true
            variables.date = dateCo
            this.$store.commit("majLangue", response.data.langue)
            this.$store.commit("majCo")
            this.$store.commit("majUtilisateur", this.trouveUtilisateurNomPrenom(this.ut_id))
            this.$store.dispatch("posteActuel", this.ut_id)
            this.$router.push("/interfaceChantier")
          }
        })
    },
    ajouter(n: string) {
      this.motdepasse += n
    },
    trouveUtilisateurNomPrenom(ut_id: number) {
      return this.utilisateurs_options.find((u: any) => u.value == ut_id).label
    },
    choixlangue(lang: string) {
      switch (lang) {
        case "FR":
          this.dictionnaire = ["Utilisateur", "Matin", "Après-midi", "Nuit", "Code"]
          break
        case "AL":
          this.dictionnaire = ["Benutzer", "Morgen", "Nachmittag", "Nacht", "Passwort"]
          break
      }
      this.postes = []
      this.postes.push({ label: this.dictionnaire[1], value: "m" })
      this.postes.push({ label: this.dictionnaire[2], value: "p" })
      this.postes.push({ label: this.dictionnaire[3], value: "n" })
    },
    estSurQuellePoste() {
      const maintenant = new Date().getHours()
      if (maintenant >= 6 && maintenant <= 12) {
        return "m"
      } else if (maintenant > 12 && maintenant <= 23) {
        return "p"
      } else {
        return "n"
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixlangue(this.$store.state.lang)
          break
      }
    })
    const url = this.$store.state.ajaxurl + "ajax_appli.php"
    axios
      .get(url, {
        params: {
          cle: cleControle(),
          action: "TABLES"
        }
      })
      .then(response => {
        if (response.data.salaries) {
          this.utilisateurs_options = response.data.salaries.map((U: any) => {
            return { label: U.ut_prenom + " " + U.ut_nom, value: U.ut_id }
          })
          this.$store.commit("majSalaries", response.data.salaries)
        }
        if (response.data.machines) {
          this.$store.commit("majMachines", response.data.machines)
        }
      })
    this.choixlangue(variables.langue)
    this.poste = this.estSurQuellePoste()
  },
  beforeDestroy() {
    this.unsubscribe = []
  }
})
